<template>
  <div class="add-active">
    <Form
      :model="formItem"
      :label-width="100"
      :label-colon="true"
      :disabled="recordSign == 3"
    >
      <FormItem label="参加时间">
        <DatePicker
          type="date"
          placeholder="请输入活动时间"
          v-model="formItem.createTime"
        ></DatePicker>
      </FormItem>
      <FormItem label="关联活动">
        <Select v-model="formItem.businessCode" filterable>
          <Option
            :value="item.activityCode"
            v-for="(item, index) in businessCodeList"
            :key="index"
            >{{ item.title }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="参加活动内容">
        <Input
          v-model.trim="formItem.content"
          type="textarea"
          show-word-limit
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="请填写活动内容(请控制在800字以内)"
          :maxlength="800"
        ></Input>
      </FormItem>
      <FormItem label="活动图片">
        <div
          class="demo-upload-list"
          v-for="(item, index) in uploadList"
          :key="index"
        >
          <template v-if="item.status === 'finished'">
            <img :src="item.url" />
            <div
              class="demo-upload-list-cover"
              v-if="recordSign != 3"
            >
              <Icon
                type="ios-trash-outline"
                @click.native="handleRemove(item)"
              ></Icon>
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info
            ></Progress>
          </template>
        </div>
        <Upload
          ref="upload"
          :data="uploadData"
          :show-upload-list="false"
          :default-file-list="image"
          :on-success="handleSuccess"
          :before-upload="handleBeforeUpload"
          :on-format-error="formatError"
          type="select"
          :action="action"
          style="display: inline-block"
          :max-size="2048"
          :format="['jpg','jpeg','png']"
          :on-exceeded-size="exceededSize"
        >
          <div v-if="recordSign != 3">
            <Icon type="ios-camera" size="80"></Icon>
          </div>
        </Upload>
      </FormItem>
    </Form>
    <Spin fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
export default {
    props: ['recordSign',"discussId"],
  data() {
    return {
      spinShow: false,
      //关联活动列表
      businessCodeList: [],
      formItem: {
        //参加活动时间
        createTime: "",
        //关联活动编码
        businessCode: "",
        //活动内容
        content: "",
      },
      //照片墙
      //默认已上传图片
      image: [],
      imgName: "",
      visible: false,
      uploadList: [],
      //图片上传签名
      autograph: [],
      // 上传图片文件名和真正路径
      filename: "",
      path: "",
      //图片上传代理路径
      action: "",
      //图片上传参数
      uploadData: {},
    };
  },
  created() {
    this.$get("/datamsg/api/finger/communist/selectActivity").then((res) => {
      if (res.code == 200) {
        this.businessCodeList = res.dataList;
      }
    });
    // 查看活动记录
    if (this.recordSign == 3) {
      this.spinShow = true;
      this.$get("/datamsg/api/finger/communist/selectById", {
        discussId: this.discussId,
      }).then((res) => {
        if (res.code == 200) {
          this.formItem.createTime = new Date(res.data.createTime);
          this.formItem.businessCode = res.data.businessCode;
          this.formItem.content = res.data.content;

          this.uploadList = res.data.images.reduce((pre, cur) => {
            pre.push({
              status: "finished",
              url: cur,
            });
            return pre;
          }, []);
          this.spinShow = false;
        }else {
            this.$Message.error({
                background: true,
                content: "获取数据失败"
            })
        }
      });
    } else if (this.recordSign == 2) {
      //修改活动
      this.spinShow = true;
      this.$get("/datamsg/api/finger/communist/selectById", {
        discussId: this.discussId,
      }).then((res) => {
        if (res.code == 200) {
          this.formItem.createTime = new Date(res.data.createTime);
          this.formItem.businessCode = res.data.businessCode;
          this.formItem.content = res.data.content;

          this.uploadList = res.data.images.reduce((pre, cur) => {
            pre.push({
              status: "finished",
              url: cur,
            });
            return pre;
          }, []);
          this.spinShow = false;
        }else {
              this.$Message.error({
                background: true,
                content: "获取数据失败"
            })
        }
      });
    }
  },
  methods: {
      //上传图片格式错误
      formatError(file) {
          this.$Notice.error({
            title: '温馨提示',
            desc: "文件" + file.name + "格式错误，请上传jpg，jpeg或png格式的图片"
        });
      },
      //上传图片大小错误
      exceededSize(file) {
          this.$Notice.error({
            title: '温馨提示',
            desc: "文件" + file.name + "过大，请上传2M以内的图片"
        });
      },
    //保存新增活动
    saveActivity() {
      let imageList = [];
      this.uploadList.map((el, index) => {
        if (el.status == "finished") {
          imageList.push(el.url);
        }
      });
      //转换时间
      let time = new Date(this.formItem.createTime);
      let timeStr =
        time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();

      //新增活动
      if (this.recordSign == 1) {
        this.$post("/datamsg/api/finger/communist/saveCommunistActivity", {
          createTime: timeStr,
          businessType: "1",
          custGlobalId: this.$route.query.custGlobalId,
          businessCode: this.formItem.businessCode,
          content: this.formItem.content,
          images: imageList,
        },).then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "新增成功",
            });
              this.$emit('pageChange',{
                 page: 1,
                pageSize: 10,
            })
            this.$emit('addActivityModalFn',false)
          } else {
            this.$Message.error({
              background: true,
              content: "修改失败",
            });
          }
        });
      } else {
        //修改活动
        this.$post("/datamsg/api/finger/communist/updateCommunistActivity", {
          discussId: this.discussId,
          createTime: timeStr,
          businessType: "1",
          businessCode: this.formItem.businessCode,
          content: this.formItem.content,
          images: imageList,
        }).then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "修改成功",
            });
            this.$emit('pageChange',{
                 page: 1,
                pageSize: 10,
            })
            this.$emit('addActivityModalFn',false)
          } else {
            this.$Message.error({
              background: true,
              content: "修改失败",
            });
          }
        });
      }
    },
    //移除图片
    handleRemove(file) {
      this.uploadList.splice(this.uploadList.indexOf(file), 1);
    },
    //图片上传成功
    handleSuccess(res, file, fileList) {
      this.image.push({
        name: file.name,
        url: this.path + this.filename,
      });
      this.uploadList.push(this.image[this.image.length - 1]);
    },
    //上传前
    handleBeforeUpload(file) {
      if (this.uploadList.length > 8) {
         this.$Notice.error({
            title: '温馨提示',
            desc: "上传图片以达到极限，最多只能上传9张图片"
        });
        return false;
      } else {
        return this.$get(
          "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
        ).then((res) => {
          if (res.code == 200) {
            let now = Date.parse(new Date()) / 1000;
            this.autograph = res.data;
            // this.action = "/ossimage";
            this.action =  window.vue.getProxy()['/oss'].target; 
            this.uploadData = {
              name: now + file.name,
              host: res.data.host,
              key: res.data.key + now + file.name,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
            this.filename = now + file.name;
            this.path = res.data.host + res.data.key;
          }
        });
      }
    },
  },
};
</script>
    
<style scoped lang='less'>
.add-active {
  background: #fcfcfc;
  .ivu-form {
    background: #fff;
  }
  .ivu-form-item {
    text-align: left;
    margin-bottom: 14px;
  }
  /deep/.ivu-select-selection {
    text-align: left;
  }
  .ivu-input-type-text {
    /deep/.ivu-input {
      width: 50%;
    }
  }

  //照片墙
  .demo-upload-list {
    display: inline-block;
    width: 150px;
    height: 150px;
    text-align: center;
    line-height: 150px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
  }
  .demo-upload-list img {
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
}
</style>