<template>
  <LiefengContent>
    <template v-slot:title
      >党员活动记录管理</template
    >
    <template v-slot:toolsbarRight>
      <Button type="primary" @click="addActivity">新增活动记录</Button>
       <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button type="success" @click="$router.push('/partyindex')">返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="currentPage"
        @hadlePageSize="pageChange"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
      <LiefengModal
        title="活动记录"
        :value="addActivityModal"
        @input="addActivityModalFn"
        width="600px"
      >
        <template v-slot:contentarea>
          <AddRecord
            ref="addRecordModal"
            @pageChange="pageChange"
            @addActivityModalFn="addActivityModalFn"
            :recordSign="recordSign"
            :discussId="discussId"
            v-if="addActivityModal"
          ></AddRecord>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="addActivityModal = false">{{
            recordSign == 1 || recordSign == 2 ? "取消" : "关闭"
          }}</Button>
          <Button
            style="margin-left: 10px"
            type="primary"
            @click="$refs.addRecordModal.saveActivity()"
            v-if="recordSign != 3"
            >保存</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/partyrecord")
import AddRecord from "@/views/party/childrens/addRecord";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
export default {
  data() {
    return {
      //查看/新增/修改标识  1代表新增，2代表修改，3代表查看
      recordSign: 1,
      addActivityModal: false,
      loading: false,
      //分页器当前页
      currentPage: 1,
      pageSize: 20,
      //分页器总数量
      total: 0,
      //表格数据
      tableData: [],
      //表头列表
      talbeColumns: [
        {
          title: "活动编码",
          key: "businessCode",
          width: 120,
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          width: 140,
          align: "center",
        },
        {
          title: "参加活动内容",
          key: "content",
          align: "center",
          render: (h, params) => {
            return (
              h("div"),
              [
                h(
                  "span",
                  {
                    style: {
                      display: "inline-block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  },
                  params.row.content
                ),
              ]
            );
          },
        },
        {
          title: "相关活动",
          key: "activityName",
          align: "center",
          render: (h, params) => {
            return (
              h("div"),
              [
                h(
                  "span",
                  {
                    style: {
                      display: "inline-block",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                    domProps: {
                      title: params.row.activityName,
                    },
                  },
                  params.row.activityName
                ),
              ]
            );
          },
        },
        {
          title: "参加时间",
          key: "joinDate",
          width: 200,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    //trigger: "click"
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down"
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.seeActivity(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.changeActivity(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.delectActivity(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      //查看/修改参数
      discussId: "",
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "活动编码",
            "姓名",
            "参加活动内容",
            "相关活动",
            "参加时间",
          ];
          let filterVal = [
            "businessCode",
            "userName",
            "content",
            "activityName",
            "joinDate"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "党员活动记录列表"
          );
        },
      });
    },
    //分页器
    pageChange(data) {
      this.loading = true;
      this.$get("/datamsg/api/finger/communist/selectCommunistActivityPage", {
        custGlobalId: this.$route.query.custGlobalId,
        ...data,
      })
        .then((res) => {
          if (res.code == 200) {
            res.dataList.map((item, index) => {
              let time = new Date(item.joinDate);
              item.joinDate =
                time.getFullYear() +
                "-" +
                (time.getMonth() + 1) +
                "-" +
                time.getDate();
            });
            this.total = res.maxCount;
            this.tableData = res.dataList;
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: "获取数据失败",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看活动
    seeActivity(value) {
      (this.recordSign = 3), (this.addActivityModal = true);
      this.discussId = value.discussId;
    },
    //修改活动
    changeActivity(value) {
      (this.recordSign = 2), (this.addActivityModal = true);
      this.discussId = value.discussId;
    },
    //删除活动
    delectActivity(value) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>确定删除该活动记录？</p>",
        onOk: () => {
          this.$post("/datamsg/api/finger/communist/deleteById", {
            discussId: value.discussId,
          }).then((res) => {
            if (res.code == 200) {
                this.$Message.success({
                background: true,
                content: "删除成功"
              })
              this.pageChange({
                page: this.pageSize,
                pageSize: this.currentPage,
              });
            }else {
               this.$Message.error({
                background: true,
                content: "删除失败"
              })
            }
          }).catch(err => {
            console.log(err);
          })
        },
      });
    },
    //新增活动记录
    addActivity() {
      this.recordSign = 1;
      this.addActivityModal = true;
    },
    addActivityModalFn(status) {
      this.addActivityModal = status;
    },
  },
  created() {
    //获取党员活动记录表格数据
    this.pageChange({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengTable,
    LiefengModal,
    LiefengContent,
    AddRecord,
  },
};
</script>
    
<style scoped lang='less'>
.active {
  min-height: 100vh;
  background: #fcfcfc;
  padding-top: 30px;
  .activeTable {
    margin-top: 30px;
    height: calc(100vh - 140px);
  }
}
</style>